$desktop: 1024px;

html {
  overflow: hidden;
}
.App {
  width: 100vw;
  height: 100vh;
}

.landing-page__btn {
  position: absolute;
  pointer-events: all;
  right: 50%;
  top: 90%;
  transform: translate(50%, -50%);
  z-index: 999999;
}
.page-container {
  position: absolute;
  // background: red;
  z-index: 999;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.ui-sticky-prev {
  position: absolute;
  pointer-events: all;
  left: 0;
  bottom: 0;
  z-index: 999999;
}
.ui-sticky-next {
  position: absolute;
  pointer-events: all;
  right: 0;
  bottom: 0;
  z-index: 999999;
}

.button {
  font-family: "NeueRational";
  border: 0;
  background: transparent;
  background: #2d6025;
  color: white;
  text-transform: uppercase;

  font-size: 15px;
  border-radius: 40px;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  margin: 20px;
  cursor: pointer;

  @media only screen and (min-width: $desktop) {
    font-size: 20px;
    border-radius: 40px;
    padding: 20px;
    padding-left: 30px;
    padding-right: 30px;
    margin: 20px;
  }
}

.button--alt {
  background: red;
  color: black;
}

.ui-get-ready {
  // position: absolute;
  // top: calc(50% - 10px);
  // left: 50%;
  .ui-match-title {
    white-space: nowrap;
    transform: translate(-50%, -50%);
    text-transform: uppercase;
    color: #dcedc1;
    font-family: "NeueRational";
    // font-style: normal;
    // font-weight: 100;
    font-size: 110px;
    line-height: 0.75;
    text-align: center;
    margin: 0%;
    padding: 0;
    @media only screen and (min-width: $desktop) {
      font-size: 210px;
      line-height: 0.75;
    }
  }
}

@import "./Animate.scss";

// ------
// .animate-enter {
//   opacity: 0;
//   transform: translate(0, 25px);
//   z-index: 1;
// }
// .animate-enter.animate-enter-active {
//   opacity: 1;
//   transform: translate(0, 0);

//   transition: opacity 250ms ease-out, transform 300ms ease;
// }
// .animate-exit {
//   opacity: 1;
//   transform: translate(0, 0);
// }
// .animate-exit.animate-exit-active {
//   opacity: 0;
//   transform: translate(0, 30px);

//   transition: opacity 250ms ease-out, transform 300ms ease;
// }
